/* You can add global styles to this file, and also import other style files */

$common-font: "Poppins", sans-serif;
$heading-font: "Montserrat", sans-serif;
$transition: 0.5s all ease;
$all-size: 16px;
$white-color: #ffffff;
$grey-color: #777777;
$black-color: #0d1820;
$gradient-green: linear-gradient(
    90deg,
    rgba(16, 195, 23, 1) 23%,
    rgba(21, 208, 154, 1) 64%
);
$main-color: #00c195;
$paragraph-color: #767676;
$main-gradient-color: linear-gradient(270deg, #00c194 0%, #00a47e 100%);

/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: $white-color;
    font-size: $all-size;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    font-family: $heading-font;
    color: $black-color;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.ptb-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}
.pt-100 {
    padding-top: 100px;
}
.pt-25 {
    padding-top: 25px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus {
        outline: 0;
    }
}
.btn.focus,
.btn:focus {
    box-shadow: none;
}
/*-- End Default CSS --*/

/*----- Home One CSS -----*/
/*-- Header Top --*/
.one-header-top {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
    padding-right: 40px;
    padding-left: 40px;

    .header-social {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:nth-child(2) {
                    a {
                        color: #1da1f2;
                    }
                }
                &:nth-child(3) {
                    a {
                        color: #d43535;
                    }
                }
                &:nth-child(4) {
                    margin-right: 0;

                    a {
                        color: #405de6;
                    }
                }
                a {
                    display: block;
                    color: #0b5998;
                    font-size: 20px;

                    &:hover {
                        transform: rotateY(360deg);
                    }
                }
            }
        }
    }
    .header-partner {
        text-align: center;

        p {
            font-size: 14px;
            color: #606060;
            margin-bottom: 0;

            a {
                display: inline-block;
                color: #232f77;

                &:hover {
                    color: #606060;
                }
            }
        }
    }
    .header-call {
        text-align: right;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
                span {
                    display: inline-block;
                    color: #606060;
                    font-size: 14px;
                }
                a {
                    display: inline-block;
                    color: #232f77;
                    font-size: 14px;

                    &:hover {
                        color: #606060;
                    }
                }
            }
        }
    }
}
/*-- End Header Top --*/

/*-- Navbar --*/
.navbar-light {
    padding-left: 55px;
    padding-right: 55px;
    background-color: $white-color;
    box-shadow: 0px 20px 20px 0px #dddddd52;

    .navbar-nav {
        .nav-link {
            color: $black-color;
            margin-right: 15px;
            margin-left: 15px;
        }
        .active > .nav-link,
        .nav-link.active,
        .nav-link.show,
        .show > .nav-link {
            color: #12ca59;
        }
        .nav-link:focus,
        .nav-link:hover {
            color: #12ca59;
        }
        .nav-link:focus,
        .nav-link:hover {
            color: #12ca59;
        }
    }
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
    }
    .side-nav {
        a {
            display: inline-block;
            color: $black-color;
        }
        .side-nav-left {
            margin-right: 14px;

            &:hover {
                color: #12ca59;
            }
        }
        .side-nav-right {
            color: $white-color;
            position: relative;
            background-color: $black-color;
            z-index: 1;
            padding: 10px 16px 12px;
            border-radius: 4px;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 4px;
                margin-right: 4px;
            }
            &:hover {
                &:before {
                    width: 0;
                }
            }
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.one-banner-area {
    position: relative;
    z-index: 1;

    .banner-shape {
        img {
            z-index: -1;
            position: absolute;

            &:nth-child(1) {
                top: 0;
                left: 0;
                max-width: 500px;
                animation: banner-ani-one 6s linear infinite;
            }
            &:nth-child(2) {
                left: 300px;
                top: 300px;
                max-width: 500px;
                animation: banner-ani-two 6s linear infinite;
            }
        }
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;

        .row {
            margin-left: 0;
            margin-right: 0;

            .col-lg-6 {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    .banner-text {
        max-width: 635px;
        margin-left: auto;
        margin-top: 50px;
        padding-right: 50px;

        .banner-video {
            margin-bottom: 20px;

            a {
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                color: $white-color;
                background-color: $black-color;
                font-size: 50px;

                &:hover {
                    background-color: #12ca59;
                }
                i {
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                }
            }
            span {
                display: inline-block;
                font-weight: 500;
                font-size: 20px;
                color: $black-color;
                position: relative;
                top: -15px;
                margin-left: 14px;
            }
        }
        h1 {
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 95px;
            line-height: 115px;
        }
        p {
            margin-bottom: 50px;
            font-weight: 500;
            color: #1a1b1e;
            font-size: 30px;
        }
        .banner-form {
            .col-lg-6 {
                padding-left: 15px;
                padding-right: 15px;
            }
            .banner-form-span {
                display: inline-block;
                border-bottom: 1px solid #dddddd96;

                span {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 20px;
                    color: $black-color;
                    padding: 15px 22px;
                    box-shadow: 0px 0px 20px 0px #dddddd96;
                }
            }
            form {
                padding: 30px 20px 30px;
                box-shadow: 0px 0px 20px 0px #dddddd96;
                background-color: $white-color;

                .form-group {
                    .form-control {
                        height: 45px;
                        background-color: #f7f7f7;
                        border: 1px solid #eeeeee;
                        border-radius: 0;
                        font-weight: 500;
                        font-size: 15px;
                        width: 100%;
                        &:focus {
                            border: 1px solid #eeeeee;
                            box-shadow: none;
                        }

                        span {
                            font-weight: 500;
                            font-size: 15px;
                            color: #9d9d9d;
                            margin-bottom: 0;
                            line-height: 30px;
                            display: block;
                        }
                        .list {
                            display: block;
                            width: 100%;

                            li {
                                display: block;
                            }
                        }
                    }
                    ::placeholder {
                        color: #9d9d9d;
                    }
                }
                .banner-form-btn {
                    width: 100%;
                    height: 45px;
                    color: $white-color;
                    font-size: 15px;
                    font-weight: 500;
                    transition: $transition;
                    position: relative;
                    z-index: 1;
                    background-color: $black-color;
                    border: 0;

                    &:before {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: $gradient-green;
                        z-index: -1;
                        transition: $transition;
                    }
                    &:hover {
                        &:before {
                            width: 0;
                        }
                    }
                }
                .banner-call {
                    width: 100%;
                    height: 45px;
                    color: $white-color;
                    font-size: 15px;
                    font-weight: 500;
                    background-color: $black-color;
                    display: block;
                    text-align: center;
                    line-height: 45px;

                    &:hover {
                        background-color: #12ca59;
                    }
                }
            }
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
        position: absolute;
        bottom: -45px;
        right: 0;
    }
    @keyframes banner-ani-one {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, 50px);
        }
        100% {
            transform: translate(0, 0px);
        }
    }
    @keyframes banner-ani-two {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, -50px);
        }
        100% {
            transform: translate(0, 0px);
        }
    }
}
/*-- End Banner --*/

/*-- About --*/
.one-section-title {
    text-align: center;
    margin-bottom: 50px;

    .sub-title {
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        background: $gradient-green;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        margin-bottom: 5px;

        &:before {
            position: absolute;
            content: "";
            width: 40px;
            height: 3px;
            right: -48px;
            bottom: 7px;
            background: $gradient-green;
        }
    }
    h2 {
        font-weight: 700;
        font-size: 60px;
        margin-bottom: 0;
    }
    p {
        margin-top: 5px;
        max-width: 1080px;
        margin-left: auto;
        margin-right: auto;
    }
}
.one-about-area {
    padding-top: 95px;

    .col-lg-6 {
        padding-left: 0;
        padding-right: 0;
    }
    .one-section-title {
        text-align: left;
        margin-bottom: 30px;
    }
    .about-content {
        max-width: 545px;
        margin-left: 70px;
        padding-top: 100px;
        padding-bottom: 100px;

        .about-tablist {
            background: $gradient-green;
            padding: 30px 30px 30px;
            margin-bottom: 20px;

            ul {
                margin-bottom: 25px;

                li {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: $white-color;
                        background-color: #3ae283;
                        font-weight: 500;
                        text-transform: uppercase;
                        padding: 10px 18px 10px;
                        border-radius: 4px;

                        &.active {
                            background: $white-color;
                            color: #121682;
                        }
                        i {
                            display: inline-block;
                            position: relative;
                            top: 4px;
                            font-size: 20px;
                            margin-right: 3px;
                        }
                    }
                }
            }
            p {
                margin-bottom: 0;
                color: $white-color;
                font-size: 15px;
            }
        }
        .about-best {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;

                li {
                    flex: 0 0 33%;
                    max-width: 33%;
                    list-style-type: none;
                    display: inline-block;
                    background-color: #f4f4f4;
                    padding: 15px 20px 15px;
                    margin-right: 15px;

                    &:last-child {
                        flex: 0 0 64%;
                        max-width: 64%;
                        margin-right: 0;
                    }
                    h4 {
                        font-family: $common-font;
                        font-weight: 500;
                        font-size: 18px;
                        color: $black-color;
                        margin-bottom: 0;
                        line-height: 28px;
                    }
                    img {
                        padding-right: 25px;
                        border-right: 1px solid #bcbcbc;
                        margin-right: 25px;
                        margin-top: -26px;
                    }
                    .sig-inner {
                        display: inline-block;

                        span {
                            display: block;
                            font-weight: 500;
                            color: #10c317;
                            margin-bottom: 3px;
                        }
                        p {
                            margin-bottom: 0;
                            font-weight: 500;
                            font-size: 14px;
                            color: #909090;
                        }
                    }
                }
            }
        }
    }
}
/*-- End About --*/

/*-- Counter --*/
.one-counter-area {
    background-color: $black-color;

    .counter-item {
        position: relative;
        margin-bottom: 30px;

        h3 {
            font-weight: 700;
            font-size: 75px;
            color: $white-color;
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
        p {
            margin-bottom: 0;
            font-size: 18px;
            color: $white-color;
            padding-left: 110px;
            padding-top: 20px;
        }
    }
}
/*-- End Counter --*/

/*-- Service --*/
.one-service-area {
    nav {
        .nav-tabs {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 0;

            .nav-default {
                display: inline-block;
                margin-bottom: 30px;
                flex: 0 0 16.6666666667%;
                max-width: 16.6666666667%;
                padding-left: 15px;
                padding-right: 15px;

                &.active {
                    .service-item {
                        &:before {
                            opacity: 1;
                        }
                        i {
                            color: $white-color;
                        }
                        span {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .service-item {
        text-align: center;
        background-color: #efefef;
        border-radius: 4px;
        padding: 20px 25px 18px;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $gradient-green;
            border-radius: 4px;
            opacity: 0;
            z-index: -1;
        }
        i {
            display: block;
            color: $black-color;
            font-size: 50px;
            margin-bottom: 15px;
            transition: $transition;
        }
        span {
            display: block;
            color: $black-color;
            font-weight: 500;
            font-size: $all-size;
            transition: $transition;
        }
    }
    .service-advisor {
        padding: 35px 35px 35px;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px #dddddd96;

        .advisor-content {
            h2 {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 20px;
            }
            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 22px;

                li {
                    list-style-type: none;
                    display: block;
                    color: #444444;
                    margin-bottom: 12px;
                    font-size: $all-size;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        display: inline-block;
                        font-size: 20px;
                        position: relative;
                        top: 3px;
                        margin-right: 3px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .advisor-link {
                display: inline-block;

                &:hover {
                    i {
                        left: -3px;
                    }
                }
                a {
                    display: inline-block;
                    font-weight: 600;
                    font-size: $all-size;
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                i {
                    display: inline-block;
                    color: #12ca59;
                    font-size: 21px;
                    position: relative;
                    top: 4px;
                    left: 0;
                    transition: $transition;
                }
            }
        }
    }
}
/*-- End Service --*/

/*-- Help --*/
.one-help-area {
    background-color: $black-color;
    position: relative;

    .help-img {
        position: absolute;
        top: 180px;
        right: 0;
        max-width: 435px;
    }
    .help-item {
        max-width: 790px;

        .one-section-title {
            text-align: left;

            h2 {
                color: $white-color;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                margin-bottom: 40px;

                &:hover {
                    .help-icon {
                        background-color: $white-color;
                    }
                }
                .help-icon {
                    display: inline-block;
                    background-color: #233644;
                    border-radius: 4px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: $transition;

                    i {
                        font-size: 38px;
                        width: 80px;
                        height: 65px;
                        line-height: 65px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        box-shadow: 0px 10px 25px 0px #091117;
                    }
                }
                .help-inner {
                    padding-left: 110px;

                    h3 {
                        font-weight: 500;
                        font-size: 20px;
                        color: $white-color;
                        margin-bottom: 10px;
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 15px;
                        color: #b2bac0;
                    }
                }
            }
        }
        .advisor-link {
            display: inline-block;

            &:hover {
                i {
                    left: -3px;
                }
            }
            a {
                display: inline-block;
                font-weight: 600;
                font-size: $all-size;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            i {
                display: inline-block;
                color: #12ca59;
                font-size: 21px;
                position: relative;
                top: 4px;
                left: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End Help --*/

/*-- Among --*/
.one-among-area {
    .among-item {
        border-radius: 4px;
        box-shadow: 0px 0px 17px 0px #dddddd59;
        transition: $transition;
        margin: 15px;

        &:hover {
            transform: translate(0, -10px);

            .among-top {
                .among-icon {
                    left: 40%;
                }
            }
            .among-bottom {
                border-bottom: 3px solid;
                border-image-source: $gradient-green;
                border-image-slice: 1;
            }
        }
        .among-top {
            position: relative;

            img {
                width: 100%;
            }
            .among-icon {
                display: inline-block;
                background-color: $white-color;
                position: absolute;
                left: 30px;
                bottom: -15px;
                border-radius: 50%;
                box-shadow: 0px 0px 20px 0px #ddddddd4;
                width: 70px;
                height: 70px;
                transition: $transition;

                i {
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 50px;
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: $transition;
                }
            }
        }
        .among-bottom {
            padding: 38px 30px 30px;
            border-bottom: 3px solid transparent;
            transition: $transition;

            h3 {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 15px;
                font-size: 15px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                font-size: 14px;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &:hover {
                    i {
                        left: -3px;
                    }
                }
            }
            i {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 4px;
                left: 0;
                transition: $transition;
            }
        }
    }
    .owl-theme .owl-dots .owl-dot span {
        width: 16px;
        height: 5px;
        background: #e5e5e5;
        margin: 5px 5px;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        background: #12ca59;
        width: 21px;
    }
    .center {
        transform: translate(0, -10px);

        .among-top {
            .among-icon {
                left: 40%;
            }
        }
        .among-bottom {
            border-bottom: 3px solid;
            border-image-source: $gradient-green;
            border-image-slice: 1;
        }
    }
}
/*-- End Among --*/

/*-- Quote --*/
.one-quote-area {
    padding-bottom: 110px;

    .row {
        box-shadow: 0px 0px 20px 0px #dddddd91;
        border-radius: 4px;
    }
    .quote-content {
        padding: 28px 35px 35px;

        h3 {
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 12px;
        }
        p {
            font-size: 15px;
            margin-bottom: 18px;
        }
        a {
            color: $white-color;
            position: relative;
            background-color: $black-color;
            z-index: 1;
            padding: 10px 80px 12px;
            border-radius: 4px;
            font-size: 17px;
            display: inline-block;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
                margin-right: 4px;
            }
            &:hover {
                &:before {
                    width: 0;
                }
            }
        }
    }
}
/*-- End Quote --*/

/*-- Team --*/
.one-team-area {
    .one-section-title {
        text-align: left;
        position: relative;

        a {
            display: inline-block;
            color: $white-color;
            background-color: $black-color;
            border-radius: 4px;
            padding: 14px 18px 14px;
            font-size: 17px;
            position: absolute;
            bottom: 0;
            right: 0;

            &:hover {
                background-color: #12ca59;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
            }
        }
    }
    .team-item {
        text-align: center;
        margin-bottom: 30px;

        &:hover {
            ul {
                li {
                    &:nth-child(1) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(3) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(4) {
                        top: -20px;
                        opacity: 1;
                    }
                }
            }
        }
        .team-img {
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 263px;
                height: 263px;
                border-radius: 50%;
                left: -4px;
                right: 0;
                top: -4px;
                border: 2px dashed #12ca59;
            }
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 4px;
                margin-left: 4px;
                position: relative;

                &:nth-child(1) {
                    transition: 0.3s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(2) {
                    transition: 0.5s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(3) {
                    transition: 0.7s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(4) {
                    transition: 0.9s;
                    opacity: 0;
                    top: 0;
                }
                a {
                    display: block;
                    background-color: $white-color;
                    text-align: center;
                    box-shadow: 0px 0px 20px 0px #ddd;
                    border-radius: 50%;

                    &:hover {
                        transform: rotateY(360deg);
                    }
                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
            text-transform: uppercase;
            color: #08104d;
        }
        span {
            display: block;
            font-size: 15px;
            color: #64677c;
        }
    }
}
/*-- End Team --*/

/*-- Testimonial --*/
.one-testimonial-area {
    .testimonial-item {
        text-align: center;
        background-color: $white-color;
        box-shadow: 0px 0px 20px 0px #dddddd85;
        padding: 30px 35px 30px;
        border-radius: 4px;
        margin-bottom: 30px;
        transition: $transition;

        &:hover,
        &.active {
            background-color: $black-color;
            transform: translate(0, -10px);

            .testimonial-top {
                ul {
                    &:before {
                        background-color: #172631;
                    }
                    &:after {
                        background-color: #172631;
                    }
                }
                .test-img {
                    img {
                        border: 8px solid #314c60;
                        box-shadow: none;
                    }
                }
                h3 {
                    color: $white-color;
                }
                span {
                    border-bottom: 1px solid #172631;
                }
            }
            .testimonial-bottom {
                span {
                    color: $white-color;
                }
                p {
                    color: #b2bac0;
                }
            }
        }
        .testimonial-top {
            margin-bottom: 15px;

            .test-img {
                position: relative;
                display: inline-block;

                img {
                    box-shadow: 0px 0px 20px 0px #dddddd85;
                    border: 8px solid $white-color;
                    border-radius: 50%;
                    transition: $transition;
                }
                i {
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 50%;
                    text-align: center;
                    color: $white-color;
                    background: $gradient-green;
                    font-size: 20px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                margin-top: 30px;
                position: relative;
                margin-bottom: 20px;

                &:before {
                    position: absolute;
                    content: "";
                    left: -35px;
                    top: 10px;
                    width: 34%;
                    height: 2px;
                    background-color: #ecf3f7;
                    transition: $transition;
                }
                &:after {
                    position: absolute;
                    content: "";
                    right: -35px;
                    top: 10px;
                    width: 34%;
                    height: 2px;
                    background-color: #ecf3f7;
                    transition: $transition;
                }
                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 3px;
                    margin-left: 3px;

                    i {
                        color: #ddd;
                        font-size: 20px;
                    }
                    .checked {
                        color: #ff9c00;
                    }
                }
            }
            h3 {
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 8px;
                transition: $transition;
            }
            span {
                display: block;
                font-size: 15px;
                color: #11c744;
                padding-bottom: 18px;
                border-bottom: 1px solid #dce8f0;
                transition: $transition;
            }
        }
        .testimonial-bottom {
            span {
                color: #3b474f;
                font-weight: 500;
                font-size: 17px;
                margin-bottom: 6px;
                display: block;
                transition: $transition;
            }
            p {
                color: #555555;
                font-size: 15px;
                margin-bottom: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End Testimonial --*/

/*-- Contact --*/
.one-contact-area {
    background-color: $black-color;

    .contact-img {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        img {
            display: none;
        }
    }
    .contact-content {
        padding: 100px 0 100px;
        max-width: 635px;
        margin-left: 50px;

        .one-section-title {
            text-align: left;
            margin-bottom: 30px;
            margin-left: 15px;

            h2 {
                color: $white-color;
            }
        }
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                margin: 0;
                color: #7f7f7f;
                display: inline-block;
                font-size: 18px;
                position: absolute;
                top: 12px;
                left: 25px;
            }
            .form-control {
                height: 50px;
                border-radius: 0;
                background-color: $white-color;
                padding-left: 50px;
                border: 0;
                font-weight: 500;
                font-size: 15px;

                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
            textarea {
                height: auto !important;
                padding-top: 12px;
            }
            ::placeholder {
                color: #7f7f7f;
            }
        }
        .contact-btn {
            color: $white-color;
            position: relative;
            background-color: $white-color;
            z-index: 1;
            padding: 11px 18px 12px 14px;
            border-radius: 4px;
            font-size: 16px;
            display: inline-block;
            opacity: 1;
            text-transform: uppercase;
            transition: $transition;
            border: 0;
            margin-top: 20px;

            &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
                margin-right: 4px;
            }
            &:hover {
                color: $black-color;

                &:before {
                    width: 0;
                }
            }
        }
        .contact-emergency {
            position: relative;
            padding-left: 75px;
            margin-top: 15px;

            i {
                display: inline-block;
                color: $white-color;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 60px;
            }
            span {
                display: block;
                font-size: $all-size;
                color: $white-color;
                margin-bottom: 2px;
            }
            a {
                display: block;
                font-weight: 500;
                font-size: 22px;
                color: $white-color;

                &:hover {
                    color: #12ca59;
                }
            }
        }
        .list-unstyled {
            color: #dc3545;
            font-size: 14px;
            margin-top: 10px;
        }
        .text-danger {
            color: #dc3545;
            margin-top: 10px;
            font-size: 20px;
        }
        .text-success {
            color: #28a745;
            margin-top: 10px;
            font-size: 20px;
        }
    }
}
/*-- End Contact --*/

/*-- Blog --*/
.one-blog-area {
    .one-section-title {
        h2 {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .blog-item {
        margin-bottom: 30px;
        transition: $transition;
        box-shadow: 0px 0px 20px 0px #ddddddab;
        background-color: $white-color;

        &:hover {
            transform: translate(0, -10px);
        }
        .blog-top {
            position: relative;

            a {
                display: block;

                img {
                    width: 100%;
                }
            }
            span {
                display: inline-block;
                color: $white-color;
                font-size: 13px;
                background: $gradient-green;
                padding: 7px 12px;
                position: absolute;
                bottom: 0;
                right: 35px;
            }
        }
        .blog-bottom {
            padding: 20px 25px 20px;

            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 18px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    color: #767676;
                    font-size: 13px;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        display: inline-block;
                        color: #395dc7;
                        font-size: 14px;
                        position: relative;
                        top: 1px;
                        margin-right: 1px;
                    }
                }
            }
            h3 {
                font-weight: 600;
                font-size: 20px;
                padding-bottom: 15px;
                border-bottom: 1px solid #e6e6e6;
                margin-bottom: 15px;

                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: #12ca59;
                    }
                }
            }
            img {
                display: inline-block;
                margin-right: 3px;
            }
            p {
                margin-bottom: 0;
                display: inline-block;
                font-size: 13px;
                color: #525252;

                a {
                    display: inline-block;
                    color: #525252;

                    &:hover {
                        color: #12ca59;
                    }
                }
            }
        }
    }
}
/*-- End Blog --*/

/*-- Footer --*/
.one-footer-area {
    background-color: $black-color;

    .footer-item {
        margin-bottom: 30px;

        .footer-logo {
            a {
                display: block;
                margin-bottom: 35px;
            }
            p {
                font-size: $all-size;
                color: #bdcbd5;
                margin-bottom: 14px;
            }
            ul {
                margin: 0;
                padding: 0;
                padding-top: 20px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        border: 1px solid #8f8f8f;
                        text-align: center;
                        border-radius: 50%;
                        color: #8f8f8f;
                        font-size: 18px;
                        margin-bottom: 0;

                        &:hover {
                            color: $white-color;
                            background-color: #8f8f8f;
                        }
                        i {
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .footer-quick {
            h3 {
                margin-bottom: 25px;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 25px;
                color: $white-color;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 11px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        font-size: $all-size;
                        color: #bdcbd5;

                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
        .footer-address {
            margin-top: 30px;

            h3 {
                margin-bottom: 25px;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 25px;
                color: $white-color;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    font-size: $all-size;
                    color: #bdcbd5;
                    margin-bottom: 15px;
                    position: relative;
                    padding-left: 33px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        font-size: 20px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    a {
                        color: #bdcbd5;

                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
    .copyright-area {
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #212e37;

        .copyright-item {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;

                        &:hover {
                            transform: translate(0, -5px);
                        }
                    }
                }
            }
            p {
                margin-bottom: 0;
                font-size: $all-size;
                color: #767676;

                a {
                    display: inline-block;
                    color: $white-color;
                    border-bottom: 1px solid $white-color;

                    &:hover {
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
/*-- End Footer --*/
/*----- End Home One CSS -----*/

/*----- Home Two CSS -----*/
.two-banner-area {
    height: 850px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.5;
    }
    .banner-content {
        position: relative;
        margin-top: -135px;
        max-width: 955px;
        margin-left: auto;
        margin-right: auto;

        span {
            display: block;
            font-weight: 500;
            font-size: 25px;
            color: $white-color;
            margin-bottom: 25px;

            img {
                max-width: 50px;
                display: inline-block;
                margin-right: 5px;
            }
        }
        h1 {
            font-weight: 700;
            font-size: 100px;
            color: $white-color;
            line-height: 110px;
            margin-bottom: 45px;
        }
        .banner-btn {
            a {
                display: inline-block;
                color: $white-color;

                i {
                    display: inline-block;
                    font-size: 20px;
                    position: relative;
                    top: 5px;
                    margin-right: 4px;
                }
            }
            .cmn-banner-btn {
                margin-right: 10px;
            }
            .banner-right-btn {
                border: 1px solid $white-color;
                border-radius: 4px;
                padding: 11px 25px 13px;
                margin-left: 10px;

                i {
                    top: 4px;
                }
                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                    border: 1px solid $black-color;
                }
            }
        }
    }
}
.cmn-banner-btn {
    color: $white-color;
    position: relative;
    background-color: $black-color;
    z-index: 1;
    padding: 12px 25px 14px;
    border-radius: 4px;
    font-size: 16px;
    display: inline-block;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $gradient-green;
        z-index: -1;
        border-radius: 4px;
        transition: $transition;
    }
    i {
        display: inline-block;
        font-size: 20px;
        position: relative;
        top: 5px;
        margin-right: 4px;
    }
    &:hover {
        color: $white-color;

        &:before {
            width: 0;
        }
    }
}
.two-banner-wrap {
    position: relative;

    .container-fluid {
        padding-left: 70px;
        padding-right: 70px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;

        .banner-time-left {
            padding: 50px 45px 50px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: 0.5;
            }
            ul {
                margin: 0;
                padding: 0;
                position: relative;

                li {
                    list-style-type: none;
                    display: inline-block;
                    position: relative;
                    padding-left: 70px;
                    margin-right: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        color: #888888;
                        font-size: 55px;
                    }
                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 16px;
                        color: #d9d9d9;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 18px;
                        color: $white-color;
                    }
                    a {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        color: $white-color;

                        &:hover {
                            color: #12ca59;
                        }
                    }
                }
            }
        }
        .banner-time-right {
            background-color: $white-color;
            padding: 50px 35px 50px 45px;
            box-shadow: 0px 10px 20px -10px #dddddd9c;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    position: relative;
                    padding-left: 70px;
                    margin-right: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        top: 3px;
                        left: 0;
                        background: linear-gradient(
                            90deg,
                            #10c317 23%,
                            #15d09a 64%
                        );
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 55px;
                    }
                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 16px;
                        color: #5d5d5d;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 18px;
                        color: #3c3b3b;
                    }
                    a {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        color: #414141;

                        &:hover {
                            color: #12ca59;
                        }
                    }
                }
            }
        }
    }
    .two-banner-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: 40px;
            }
            .owl-next {
                opacity: 1;
                right: 40px;
            }
        }
        .owl-prev {
            position: absolute;
            top: 45%;
            left: 30px;
            font-size: 50px !important;
            color: #fff !important;
            opacity: 0;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            position: absolute;
            top: 45%;
            right: 30px;
            font-size: 50px !important;
            color: #fff !important;
            opacity: 0;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: transparent !important;
            }
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
    }
}
/*-- End Banner --*/

/*-- Mission --*/
.two-mission-area {
    .one-section-title {
        h2 {
            max-width: 825px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .mission-content {
        position: relative;
        margin-bottom: -35px;

        img {
            border-radius: 5px;
        }
        .mission-bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding-right: 50px;
            padding-left: 50px;

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style-type: none;
                    display: inline-block;
                    border-right: 1px dashed #000000;
                    background-color: $white-color;
                    border-radius: 4px;

                    &:nth-child(1) {
                        flex: 0 0 40%;
                        max-width: 40%;
                        background-color: unset;
                        border-right: 0;
                    }
                    &:nth-child(2) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(3) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(4) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(5) {
                        flex: 0 0 15%;
                        max-width: 15%;
                        border-right: 0;
                    }
                    a {
                        display: inline-block;
                        border-radius: 50%;
                        color: $white-color;
                        background-color: $black-color;
                        text-align: center;
                        font-size: 45px;
                        margin-right: 15px;

                        i {
                            width: 75px;
                            height: 75px;
                            line-height: 75px;
                        }
                        &:hover {
                            background-color: #12ca59;
                        }
                    }
                    p {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 20px;
                        color: $white-color;
                        margin-bottom: 0;
                        position: relative;
                        top: -13px;
                    }
                    .mission-item {
                        background-color: $white-color;
                        text-align: center;
                        padding: 20px 20px 18px;

                        i {
                            display: block;
                            background: $gradient-green;
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            font-size: 60px;
                            margin-bottom: 14px;
                        }
                        span {
                            display: block;
                            font-weight: 500;
                            font-size: $all-size;
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}
/*-- End Mission --*/

/*-- Counter --*/
.two-counter-area {
    padding-top: 135px;
}
/*-- End Counter --*/

/*-- Among --*/
.two-among-area {
    .among-item {
        margin: 0;
        margin-bottom: 30px;
        background-color: #edfffb;

        &:hover {
            background-color: $white-color;

            .among-top {
                .among-icon {
                    transform: rotateY(360deg);
                }
            }
            .among-bottom {
                border-bottom: none;
            }
        }
        .among-top {
            .among-icon {
                left: 40%;
            }
        }
        .among-bottom {
            border-bottom: none;
            padding: 38px 20px 30px;

            h3 {
                font-size: 19px;
            }
        }
    }
}
/*-- End Among --*/

/*-- House --*/
.two-house-area {
    // background-image: url("assets/img/home-two/architecture.webp");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $black-color;
        opacity: 0.8;
    }
    .house-item {
        margin-bottom: 30px;
        margin-right: 60px;
        text-align: center;
        position: relative;
        transition: $transition;

        &:hover {
            transform: translate(0, -10px);

            .house-icon {
                &:before {
                    transform: rotate(200deg);
                }
                &:after {
                    transform: rotate(-200deg);
                }
            }
            a {
                color: #12ca59;

                i {
                    left: -1px;
                    color: #12ca59;
                }
            }
        }
        .house-icon {
            display: inline-block;
            position: relative;
            text-align: center;
            width: 80px;
            height: 80px;
            margin-top: 15px;
            margin-bottom: 35px;

            &:before {
                position: absolute;
                content: "";
                width: 80px;
                height: 80px;
                left: 0;
                right: 0;
                top: 0;
                background-color: $white-color;
                margin-left: auto;
                margin-right: auto;
                transform: rotate(35deg);
                transition: $transition;
            }
            &:after {
                position: absolute;
                content: "";
                width: 80px;
                height: 80px;
                left: 0;
                right: 0;
                top: 0;
                background-color: $white-color;
                margin-left: auto;
                margin-right: auto;
                transform: rotate(55deg);
                opacity: 0.5;
                transition: $transition;
            }
            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 45px;
                z-index: 1;
                position: relative;
                text-align: center;
                margin-top: 15px;
            }
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 12px;
        }
        p {
            color: #dfdfdf;
            font-size: 15px;
            margin-bottom: 15px;
        }
        a {
            display: inline-block;
            font-weight: 600;
            font-size: 15px;
            color: $white-color;

            i {
                display: inline-block;
                font-size: 18px;
                position: relative;
                top: 2px;
                left: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End House --*/

/*-- Team --*/
.two-team-area {
    padding-top: 195px;
}
/*-- End Team --*/

/*-- Lover --*/
.two-lover-area {
    .lover-item {
        margin-bottom: 30px;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0px 0px 20px 0px #dddddda3;
            padding: 40px 30px 40px;

            li {
                list-style-type: none;
                display: inline-block;
                flex: 0 0 35%;
                max-width: 35%;

                &:last-child {
                    flex: 0 0 65%;
                    max-width: 65%;
                }
                .lover-img {
                    position: relative;
                    display: inline-block;

                    &:before {
                        position: absolute;
                        content: "";
                        width: 1px;
                        height: 130px;
                        right: -23px;
                        top: 0;
                        background-color: #dddddd;
                    }
                    img {
                        border-radius: 50%;
                        border: 5px solid $white-color;
                        box-shadow: 0px 0px 20px 0px #dddddda3;
                    }
                    i {
                        display: inline-block;
                        color: $white-color;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        background: $gradient-green;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
                .lover-content {
                    h3 {
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 7px;
                    }
                    span {
                        display: block;
                        font-size: 15px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 10px;
                    }
                    ul {
                        display: block;
                        margin: 0;
                        padding: 0;
                        box-shadow: none;

                        li {
                            flex: 0 0 100%;
                            max-width: 100%;

                            &:last-child {
                                flex: 0 0 100%;
                                max-width: 100%;
                            }
                            i {
                                display: inline-block;
                            }
                            .checked {
                                color: #ff9c00;
                            }
                        }
                    }
                }
            }
        }
    }
}
/*-- End Lover --*/

/*-- FAQ --*/
.two-faq-area {
    background-color: transparent;
    background-image: unset;

    .faq-img-two {
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }
    .faq-content {
        margin-bottom: 30px;
    }
}
/*-- End FAQ --*/

/*-- Blog --*/
.two-blog-area {
    .blog-item {
        background-color: #edfffb;

        &:hover {
            background-color: $white-color;
        }
    }
}
/*-- End Blog --*/

/*-- Subscribe --*/
.two-subscribe-area {
    background-color: #edfffb;

    .subscribe-wrap {
        background-image: unset;
        padding: 0;

        &:before {
            display: none;
        }
        h2 {
            color: $black-color;
            margin-bottom: 0;
        }
        .newsletter-form {
            max-width: 100%;
        }
    }
}
/*-- End Subscribe --*/
/*----- End Home Two CSS -----*/

/*----- Preloader CSS -----*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $gradient-green;
}
.spinner {
    width: 40px;
    height: 40px;
    background-color: $white-color;
    margin: 100px auto;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
/*----- End Preloader CSS -----*/

/*----- Back to Top CSS ------*/
#toTop {
    position: fixed;
    bottom: 30px;
    right: 0;
    cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background: #3fc0b5;
        color: $white-color;
        height: 45px;
        width: 45px;
        line-height: 45px;
        display: inline-block;
        text-align: center;
        font-size: 25px;
        border-radius: 50%;
        transition: $transition;
        margin-right: 10px;
        margin-bottom: 28px;
        box-shadow: 0px 0px 14px 0px #41c7bc;

        &:hover {
            background: $black-color;
            color: $white-color;
            box-shadow: 0px 0px 14px 0px #333333a6;
        }
    }
}
/*----- End Back to Top CSS -----*/

/*----- main-section-title CSS -----*/
.main-section-title {
    margin-bottom: 45px;
    text-align: center;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 12px;
    }
    h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 0;
    }
}

/*----- main-default-btn CSS -----*/
.main-default-btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: $main-color;
    color: $white-color;
    border: none;
    font-size: 15px;
    font-weight: 400;
    transition: $transition;
    border-radius: 10px;

    &:hover {
        background-color: $black-color;
    }
}

/*----- Main Top Header Area CSS -----*/
.main-top-header {
    background-color: $black-color;
    padding: {
        top: 15px;
        bottom: 15px;
    }
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        }
    }
}
.top-header-information {
    padding: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 20px;
        font-size: 14px;

        &:last-child {
            margin-right: 0;
        }
        span {
            color: $white-color;
            font-weight: 600;
            font-size: 14px;
        }
        a {
            color: #dddddd;

            &:hover {
                color: $main-color;
            }
        }
    }
}
.top-header-social {
    padding: 0;
    margin-bottom: 0;
    text-align: end;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
        a {
            i {
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background-color: $white-color;
                color: $black-color;
                text-align: center;
                border-radius: 50px;
                font-size: 15px;
                transition: $transition;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Main Navbar Area CSS -----*/
.main-navbar {
    .navbar {
        &.is-sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            background-color: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        }
        &.navbar-light {
            padding: {
                left: 0;
                right: 0;
                top: 15px;
                bottom: 15px;
            }
            background-color: $white-color;
            box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);

            .navbar-nav {
                margin: auto;
                .nav-item {
                    position: relative;
                    padding: 0;
                    a {
                        color: $black-color;
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        font-weight: 400;
                        margin: {
                            left: 20px;
                            right: 20px;
                        }
                        &:hover,
                        &:focus,
                        &.active {
                            color: $main-color;
                            position: relative;
                            transition: $transition;
                        }
                    }
                    &:last-child {
                        a {
                            margin-right: 0;
                        }
                    }
                    &:first-child {
                        a {
                            margin-left: 0;
                        }
                    }
                    &:hover,
                    &.active {
                        a {
                            color: $main-color;
                        }
                    }
                }
            }
            .others-option {
                .option-item {
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }
                    .log-in {
                        color: $black-color;
                        font-weight: 400;
                        transition: $transition;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    .sign-up {
                        display: inline-flex;
                        padding: 15px 25px;
                        background: $main-gradient-color;
                        color: $white-color;
                        font-weight: 400;
                        transition: $transition;
                        border-radius: 5px;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 50%;
                            width: 550px;
                            height: 550px;
                            margin: auto;
                            background-color: $main-color;
                            border-radius: 50%;
                            z-index: -1;
                            transform-origin: top center;
                            transform: translateX(-50%) translateY(-5%)
                                scale(0.4);
                            transition: transform 0.9s;
                        }
                        &:hover {
                            color: $white-color;

                            &:before {
                                transition: transform 1s;
                                transform: translateX(-45%) translateY(0)
                                    scale(1);
                                transform-origin: bottom center;
                            }
                        }
                    }
                    .call-btn {
                        color: $black-color;
                        font-weight: 400;
                        position: relative;
                        top: 1px;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
        }
        .container-fluid {
            padding: {
                left: 30px;
                right: 30px;
            }
        }
        &.navbar-style-two {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 9;
            background-color: transparent !important;
            box-shadow: unset;

            &.is-sticky {
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                background-color: $white-color !important;

                &.navbar-light {
                    .others-option {
                        .option-item {
                            .log-in {
                                color: $black-color;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                            .sign-up {
                                background: $main-gradient-color;
                                color: $white-color;

                                &::before {
                                    background-color: $black-color;
                                }
                            }
                            .call-btn {
                                color: $black-color;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            &.navbar-light {
                .logo {
                    padding: 0 55px 0 0;
                }
                .navbar-nav {
                    margin-right: auto;
                    margin-left: 0;
                }
                .others-option {
                    .option-item {
                        .log-in {
                            color: $white-color;
                            font-weight: 500;

                            &:hover {
                                color: $black-color;
                            }
                        }
                        .sign-up {
                            background: $white-color;
                            color: $main-color;

                            &::before {
                                background-color: $black-color;
                            }
                        }
                        .call-btn {
                            color: $white-color;
                            font-weight: 500;

                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
        &.navbar-style-black-color {
            &.navbar-light {
                background-color: $black-color !important;

                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;

                            &:hover,
                            &:focus,
                            &.active {
                                color: $main-color;
                            }
                        }
                    }
                }
                .others-option {
                    .option-item {
                        .log-in {
                            color: $white-color;

                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*----- Main Header Area CSS -----*/
.main-header-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    .main-top-header {
        background-color: transparent;
    }
    .main-navbar {
        .container-fluid {
            padding: {
                left: 30px;
                right: 30px;
            }
        }
        .navbar {
            &.is-sticky {
                &.navbar-light {
                    border-radius: 0;
                    padding: {
                        left: 30px;
                        right: 30px;
                    }
                }
            }
            &.navbar-light {
                padding: {
                    left: 20px;
                    right: 20px;
                    top: 15px;
                    bottom: 15px;
                }
                border-radius: 5px;
            }
        }
    }
}

/*----- Main Banner Area CSS -----*/
.main-banner-area {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: {
        top: 100px;
        bottom: 100px;
    }
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        }
    }
}
.main-banner-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    .banner-popup {
        margin-top: 20px;
        position: relative;
        left: 5px;

        .video-btn {
            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                background: $main-gradient-color;
                color: $white-color;
                font-size: 30px;
                margin-right: 10px;
                transition: $transition;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            span {
                color: $black-color;
                font-weight: 500;
                font-size: 14px;
                position: relative;
                top: -5px;
            }
        }
    }
}
.main-banner-form-wrap {
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid $white-color;
    box-sizing: border-box;
    box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
    border-radius: 10px;
    padding: 50px 65px;
    max-width: 550px;
    margin-left: auto;

    h3 {
        font-size: 24px;
        font-weight: 600;
        color: $white-color;
        margin-bottom: 25px;
    }
    form {
        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500;
                color: $white-color;
                font-size: 15px;
            }
            .nice-select {
                float: unset;
                height: 55px !important;
                line-height: 55px;
                color: $paragraph-color;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: 20px;
                    right: 35px;
                }
                .list {
                    background: $main-gradient-color;
                    border-radius: 10px;
                    margin-top: 0;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .option {
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        }
                        color: $white-color;
                        font-weight: 500;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
                &:after {
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -5.5px;
                }
            }
        }
        .calculat-button {
            text-align: center;
            margin-top: 30px;

            .default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $main-color;
                color: $white-color;
                width: 100%;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $black-color;
                }
            }
        }
    }
}

/*----- About Style Area CSS -----*/
.about-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.with-black-color {
        background-color: $black-color;

        .about-style-wrap-content {
            h3 {
                color: $white-color;
            }
            .bold {
                color: $white-color;
            }
            .about-list-tab {
                .nav {
                    border-bottom: 1px solid #f8fafb;

                    .nav-item {
                        .nav-link {
                            color: $white-color;
                        }
                    }
                }
                .tab-content {
                    .tab-pane {
                        .content {
                            p {
                                color: $white-color;
                            }
                            .list {
                                li {
                                    color: $white-color;
                                }
                            }
                            .tab-btn {
                                margin-top: 25px;

                                .main-default-btn {
                                    color: $white-color;

                                    &:hover {
                                        background-color: $white-color;
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .information-card {
            .icon {
                i {
                    background: rgba(0, 193, 149, 0.2);
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $main-color;
                    }
                }
            }
        }
    }
}
.about-style-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        left: 15px;
    }
    border-radius: 10px;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 5px;
        border: 1px solid $main-color;
    }
    img {
        border-radius: 10px;
    }
    .quote-content {
        background-color: $main-color;
        padding: 25px;
        position: absolute;
        left: 0;
        bottom: 30px;
        max-width: 418px;
        border-radius: 0px 20px 20px 0px;
        z-index: 1;

        p {
            color: $white-color;
            font-size: 15px;
        }
        span {
            font-weight: 600;
            color: $white-color;
        }
        &::before {
            content: "\ee32";
            font-family: "boxicons";
            font-size: 30px;
            color: $white-color;
            z-index: -1;
            position: absolute;
            right: 25px;
            bottom: 25px;
            line-height: 1;
        }
    }
}
.about-style-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
    }
    .counter-box {
        background: #e5f9f4;
        border-radius: 10px;
        text-align: center;
        padding: 30px 25px;
        margin-top: 15px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0%;
            height: 0%;
            transition: all 0.5s ease-in-out;
            background: $main-gradient-color;
            z-index: -1;
            opacity: 0;
            border-radius: 50%;
            transform: scale(1.5);
        }
        h4 {
            color: $main-color;
            font-size: 65px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $black-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
            &::before {
                opacity: 1;
                visibility: visible;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.about-style-image-wrap {
    position: relative;
    z-index: 1;
    text-align: center;

    .certified-image {
        position: absolute;
        right: 75px;
        bottom: 0;
    }
}
.about-style-wrap-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
    }
    .about-list-tab {
        margin-top: 25px;

        .nav {
            padding: 0;
            margin-bottom: 0;
            border: none;
            border-bottom: 1px solid #dddddd;

            .nav-item {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
                .nav-link {
                    transition: $transition;
                    color: $black-color;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    font-weight: 500;
                    font-size: 15px;
                    position: relative;
                    padding-bottom: 20px;

                    &::before {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 0%;
                        background-color: $main-color;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                    }
                    &:hover,
                    &.active {
                        color: $main-color;

                        &::before {
                            width: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                .content {
                    margin-top: 25px;

                    .list {
                        padding: 0;
                        margin-bottom: 0;

                        li {
                            list-style-type: none;
                            color: $black-color;
                            font-weight: 400;
                            font-size: $all-size;
                            margin-bottom: 18px;
                            position: relative;
                            padding-left: 22px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                            i {
                                position: absolute;
                                color: $main-color;
                                font-size: 18px;
                                left: 0;
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.about-style-shape-1 {
    position: absolute;
    right: 0;
    top: -50px;
    z-index: -1;
}
.about-style-area-with-full-width {
    .container-fluid {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
}
.about-style-full-content {
    max-width: 640px;
    margin-left: auto;
    padding-right: 25px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
    }
    .inner-content {
        margin-top: 25px;

        h4 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 14px;
        }
        p {
            margin-bottom: 0;
            font-size: 15px;
        }
    }
    .about-btn {
        margin-top: 25px;

        .main-default-btn {
            color: $white-color;
        }
    }
}
.about-full-image-wrap {
    position: relative;

    .counter-box {
        background: rgba(13, 24, 32, 0.9);
        border-radius: 0;
        text-align: center;
        padding: 30px 25px;
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 1;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0%;
            height: 0%;
            transition: all 0.5s ease-in-out;
            background: $main-gradient-color;
            z-index: -1;
            opacity: 0;
            border-radius: 50%;
            transform: scale(1.5);
        }
        h4 {
            color: $white-color;
            font-size: 55px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $white-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
            &::before {
                opacity: 1;
                visibility: visible;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.about-mortgage-calculator-form {
    background: $main-color;
    box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
    border-radius: 10px;
    padding: 55px 65px;
    border-radius: 10px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 25px;
        color: $white-color;
    }
    form {
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                margin-bottom: 0;
                font-size: 18px;
                position: absolute;
                top: 11px;
                left: 15px;
                color: #555555;
            }
            .form-control {
                height: 50px;
                border-radius: 4px;
                border: 1px solid #e3e9ed;
                font-weight: 400;
                font-size: 14px;
                padding-left: 40px;
                width: 100%;
                color: #555555;
                background-color: #f8fafb;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #f1f1f1;
                }
            }
            &::placeholder {
                color: #7f7f7f;
            }
            .dropdown {
                position: relative;

                a {
                    display: block;
                    height: 50px;
                    border-radius: 4px;
                    color: #555555;
                    background-color: #f8fafb;
                    font-weight: 400;
                    font-size: 14px;
                    border: 1px solid #e3e9ed;
                    text-align: left;
                    padding-left: 40px;
                    line-height: 35px;

                    &::after {
                        border-top: 7px solid;
                        position: absolute;
                        top: 22px;
                        right: 18px;
                    }
                    &:focus {
                        box-shadow: none;
                        border: 1px solid #f1f1f1;
                    }
                }
                .dropdown-menu.show {
                    display: block;
                    width: 100%;
                }
            }
        }
        .main-default-btn {
            border: 0;
            width: 100%;
            background-color: $black-color;

            &:hover {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
}

/*----- Buying Home Area CSS -----*/
.buying-home-area {
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
}
.buying-home-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        right: 15px;
    }
    img {
        border-radius: 0px 10px 10px 0px;
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 10px;
        border: 1px solid $main-color;
    }
    .counter-box {
        background: $main-color;
        border-radius: 10px 0px 0px 10px;
        text-align: center;
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: 45px;

        h4 {
            color: $white-color;
            font-size: 65px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $white-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
        }
    }
}
.buying-home-content {
    padding-left: 15px;
    max-width: 750px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
    }
    .buying-inner-box {
        margin-top: 30px;
        position: relative;
        padding-left: 100px;

        .icon {
            position: absolute;
            left: 0;
            top: 1.5px;

            i {
                display: inline-block;
                width: 80px;
                height: 75px;
                line-height: 75px;
                border-radius: 10px;
                background: #e5f9f4;
                color: $main-color;
                font-size: 45px;
                text-align: center;
                transition: $transition;
            }
        }
        h4 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            font-size: 15px;
        }
        &:hover {
            .icon {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Mortgage Quote Area CSS -----*/
.mortgage-quote-area {
    .row {
        background-color: $black-color;
        border-radius: 10px;
    }
}
.mortgage-quote-content {
    padding: 70px 50px 80px;

    h3 {
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 15px;
        color: $white-color;
    }
    p {
        font-size: 15px;
        margin-bottom: 25px;
        color: $white-color;
    }
    .quote-btn {
        display: inline-block;
        padding: 15px 30px;
        border-radius: 5px;
        background: $main-color;
        color: $white-color;
        transition: $transition;

        &:hover {
            background: $white-color;
            color: $main-color;
        }
    }
}


/*----- Why Choose Us Area CSS -----*/

.why-choose-us-card {
    .choose-image {
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;

        img {
            border-radius: 10px 10px 0px 0px;
            transition: $transition;
        }
    }
    .choose-content {
        background: #f5f7fb;
        border-radius: 0px 0px 10px 10px;
        padding: 30px 25px;
        transition: $transition;

        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
            line-height: 1.5;
            transition: $transition;
        }
        p {
            font-size: 15px;
            margin-bottom: 20px;
            transition: $transition;
        }
        .choose-btn {
            display: inline-block;
            padding: 15px 30px;
            border-radius: 5px;
            background: $main-color;
            color: $white-color;
            transition: $transition;
        }
    }
    &:hover {
        .choose-image {
            img {
                transform: scale(1.2);
            }
        }
        .choose-content {
            background-color: $main-color;

            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            .choose-btn {
                background: $white-color;
                color: $main-color;
            }
        }
    }
}
.why-choose-us-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #dddddd;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover,
                &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 30px;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.why-choose-us-area-without-bg {
    .main-section-title {
        max-width: 650px;
        margin: 0 auto 45px;
    }
}
.why-choose-us-item {
    background-color: #f5f7fb;
    padding: 35px;
    margin-bottom: 30px;
    transition: $transition;

    &.with-border {
        background-color: transparent;
        border: 1px solid #f5f7fb;
    }
    .choose-image {
        margin-bottom: 25px;
    }
    .choose-content {
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 0;
            line-height: 1.5;
            transition: $transition;
        }
        .list {
            padding: 0;
            margin: {
                top: 20px;
                bottom: 25px;
            }
            li {
                list-style-type: none;
                color: #767676;
                margin-bottom: 15px;
                position: relative;
                padding-left: 22px;
                transition: $transition;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    color: $main-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: $transition;
                }
            }
        }
        .choose-btn {
            display: inline-block;
            padding: 15px 30px;
            border-radius: 5px;
            background: $main-color;
            color: $white-color;
            transition: $transition;
        }
    }
    &:hover {
        background-color: $black-color;
        transform: translateY(-5px);

        .choose-content {
            h3 {
                color: $white-color;
            }
            .list {
                li {
                    color: $white-color;

                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*----- Staff Area CSS -----*/
.staff-card {
    margin-bottom: 30px;

    .staff-image {
        transition: $transition;
        position: relative;
        z-index: 1;
        padding: {
            top: 0;
            left: 0;
        }
        img {
            border-radius: 15px;
        }
        &::before {
            position: absolute;
            content: "";
            height: 340px;
            width: 270px;
            background-color: transparent;
            left: 0;
            top: 0;
            z-index: -1;
            border-radius: 10px;
            border: 1px solid $main-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        .social-link {
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 0px 10px 10px 0px;
            background-color: $main-color;
            padding: 25px;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    i {
                        display: inline-block;
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        background-color: $white-color;
                        color: $main-color;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 15px;
                        transition: $transition;

                        &:hover {
                            background-color: $black-color;
                            color: $white-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .staff-content {
        text-align: center;
        margin-top: 30px;

        h3 {
            color: $black-color;
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 0;
        }
        span {
            color: $paragraph-color;
            display: inline-block;
            margin-top: 10px;
        }
    }
    &:hover {
        .staff-image {
            padding: {
                top: 10px;
                left: 10px;
            }
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .social-link {
                opacity: 1;
                visibility: visible;
                bottom: 35px;
            }
        }
    }
    &.without-border {
        .staff-image {
            border-radius: 15px;
            overflow: hidden;

            &::before {
                display: none;
            }
            img {
                border-radius: 15px;
                transition: $transition;
            }
        }
        &:hover {
            .staff-image {
                padding: {
                    top: 0;
                    left: 0;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &.without-border-radius {
        .staff-image {
            border-radius: 0;
            text-align: center;

            img {
                border-radius: 0;
            }
            .social-link {
                left: 0;
                right: 0;
                border-radius: 0;
                max-width: 220px;
                margin: auto;
            }
        }
    }
}
.staff-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #dddddd;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover,
                &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*----- Need Help FAQ Area CSS -----*/
.need-help-faq-area {
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
}
.need-help-faq-content {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
    }
    .need-help-faq-accordion {
        margin-top: 30px;

        .accordion-item {
            margin-bottom: 30px;
            transition: $transition;
            border-radius: 5px;
            border: none;

            .accordion-button {
                color: $black-color;
                position: relative;
                text-align: start;
                box-shadow: unset;
                margin-bottom: 0;
                display: block;
                border: none;
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                padding: 0 45px 0 0;
                transition: $transition;
                background-color: transparent;

                &::before {
                    position: absolute;
                    right: 0;
                    top: 3.5px;
                    content: "-";
                    font-size: 15px;
                    color: $transition;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    text-align: center;
                    background-color: $main-color;
                    color: $white-color;
                    border-radius: 50px;
                }
                &.collapsed {
                    &::before {
                        content: "+";
                        color: $white-color;
                    }
                }
            }
            .accordion-body {
                padding: 20px 0 0 0;
                transition: $transition;
                border: none;

                p {
                    color: $paragraph-color;
                    font-size: 15px;
                }
            }
            .accordion-collapse {
                border: none;
            }
            &:last-child {
                margin-bottom: 0;

                .accordion-body {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.need-help-faq-image {
    margin-left: 50px;
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        left: 15px;
    }
    img {
        border-radius: 10px 0px 0px 10px;
        border: 1px solid #eeeeee;
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 10px;
        border: 1px solid $main-color;
    }
    .help-box {
        position: absolute;
        left: 0;
        bottom: 35px;
        background-color: $main-color;
        border-radius: 0 10px 10px 0;
        padding: 30px;
        text-align: center;

        h3 {
            font-weight: bold;
            color: $white-color;
            font-size: 45px;
            margin-bottom: 12px;
            line-height: 1;
        }
        span {
            color: $white-color;
        }
    }
}

/*----- Testimonials Style Area CSS -----*/
.testimonials-style-area {
    background-color: #f5f7fb;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-ffffff {
        background-color: $white-color;
    }
}
.testimonials-style-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    &.with-border-color {
        border: 1px solid #dddddd;
        padding: 35px;
        border-radius: 5px;
    }
}
.testimonials-style-card {
    .info {
        position: relative;
        padding: {
            left: 90px;
            top: 30px;
            bottom: 30px;
        }
        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 45px;
            text-align: center;
            border-radius: 50px;
            background-color: $main-color;
            color: $white-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h4 {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: bold;
        }
        span {
            color: $paragraph-color;
            font-size: 14px;
        }
    }
    p {
        margin-bottom: 0;
    }
    .star-list {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        }
        li {
            margin-right: 5px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
            i {
                color: #ffc700;
                font-size: 18px;
            }
        }
    }
}
.testimonials-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #dddddd;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover,
                &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 30px;
            text-align: start;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}


/*----- Let’s Contact Area CSS -----*/
.let-contact-area {
    background-color: $black-color;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
    &.with-main-color {
        background-color: $main-color;

        .let-contact-form {
            .sub-title {
                color: $white-color;
            }
            form {
                .let-contact-btn {
                    .main-default-btn {
                        background-color: $black-color;
                        color: $white-color;

                        &:hover {
                            background-color: $white-color;
                            color: $main-color;
                        }
                    }
                }
                .text-danger {
                    color: $white-color !important;
                }
            }
        }
    }
}
.let-contact-form {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
        position: relative;
        left: 8px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $white-color;
        position: relative;
        left: 8px;
    }
    form {
        margin-top: 25px;

        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500;
                color: $white-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: #f8fafb;
                border: 1px solid #e3e9ed;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: #555555;

                &::placeholder {
                    color: #555555;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea {
                &.form-control {
                    min-height: 120px;
                }
            }
            .list-unstyled {
                li {
                    font-size: 12px;
                    margin-top: 10px;
                    color: red;
                }
            }
        }
        .let-contact-btn {
            .main-default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $main-color;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        .text-danger {
            color: $main-color !important;
            font-size: 15px;
            font-weight: 500;
            margin: {
                top: 10px;
                bottom: 0;
            }
        }
    }
}
.let-contact-map {
    padding-left: 50px;

    iframe {
        width: 100%;
        height: 800px;
        margin-bottom: -10px;
    }
}
.let-contact-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 30%;
}
.let-contact-form-with-gray-color {
    margin-top: 50px;
    position: relative;
    z-index: 1;

    .main-section-title {
        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
    form {
        background: #f5f7fb;
        padding: 50px;
        max-width: 880px;
        margin: auto;
        border-radius: 5px;

        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500;
                color: $black-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: $white-color;
                border: 1px solid #e3e9ed;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: #555555;

                &::placeholder {
                    color: #555555;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea {
                &.form-control {
                    min-height: 120px;
                }
            }
            .list-unstyled {
                li {
                    font-size: 12px;
                    margin-top: 10px;
                    color: red;
                }
            }
        }
        .let-contact-btn {
            .main-default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $black-color;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        .text-danger {
            color: $main-color !important;
            font-size: 15px;
            font-weight: 500;
            margin: {
                top: 10px;
                bottom: 0;
            }
        }
    }
    .let-contact-shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}

/*----- Blog Style Area CSS -----*/
.blog-style-left-card {
    .blog-image {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        .tag {
            display: inline-block;
            padding: 5px 15px;
            border-radius: 5px;
            background-color: $main-color;
            color: $white-color;
            position: absolute;
            left: 20px;
            top: 20px;
            font-size: 14px;
        }
        img {
            border-radius: 10px;
            transition: $transition;
        }
    }
    .blog-content {
        margin-top: 25px;

        .post-meta {
            padding: 0;
            margin-bottom: 25px;

            li {
                color: $paragraph-color;
                font-size: 15px;
                list-style-type: none;
                display: inline-block;
                margin-right: 15px;
                position: relative;
                padding-left: 22px;

                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                .post-author {
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                    span {
                        font-weight: 400;
                        font-size: 15px;
                        color: $paragraph-color;
                    }
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 2.8px;
                    color: $main-color;
                }
            }
        }
        h3 {
            font-size: 24px;
            margin-bottom: 15px;
            font-weight: bold;

            a {
                color: $black-color;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        .blog-image {
            img {
                transform: scale(1.2);
            }
        }
    }
}
.blog-style-right-card {
    padding-left: 45px;

    .card-box {
        margin-bottom: 40px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e3e9ed;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        .blog-content {
            .tag {
                display: inline-block;
                padding: 5px 15px;
                border-radius: 5px;
                background-color: $main-color;
                color: $white-color;
                font-size: 14px;
                margin-bottom: 22px;
                transition: $transition;

                &:hover {
                    background-color: $black-color;
                }
            }
            .post-meta {
                padding: 0;
                margin-bottom: 15px;

                li {
                    color: $paragraph-color;
                    font-size: 15px;
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 15px;
                    position: relative;
                    padding-left: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 2.8px;
                        color: $main-color;
                    }
                }
            }
            h3 {
                font-size: 24px;
                margin-bottom: 12px;
                font-weight: bold;
                line-height: 1.5;

                a {
                    color: $black-color;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}
.blog-style-card {
    margin-bottom: 30px;
    background-color: #f5f7fb;
    padding: 30px;
    transition: $transition;

    .tag {
        display: inline-block;
        padding: 5px 15px;
        border-radius: 5px;
        background-color: $main-color;
        color: $white-color;
        margin-bottom: 28px;
    }
    .post-meta {
        padding: 0;
        margin-bottom: 20px;

        li {
            color: $paragraph-color;
            font-size: 14px;
            list-style-type: none;
            display: inline-block;
            margin-right: 15px;
            position: relative;
            padding-left: 22px;
            transition: $transition;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            .post-author {
                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 5px;
                    display: inline-block;
                }
                span {
                    font-weight: 400;
                    font-size: 14px;
                    color: $paragraph-color;
                    transition: $transition;
                }
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                color: $main-color;
                transition: $transition;
            }
        }
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 15px;

        a {
            color: $black-color;
            transition: $transition;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
        font-size: 15px;
    }
    &:hover {
        background-color: $black-color;

        .post-meta {
            li {
                color: $white-color;

                .post-author {
                    span {
                        color: $white-color;
                    }
                }
                i {
                    color: $main-color;
                }
            }
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
    }
}
.blog-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #dddddd;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover,
                &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*----- Subscribe Style Area CSS -----*/

.subscribe-style-wrap-content {
    h2 {
        color: $white-color;
        font-weight: bold;
        margin-bottom: 0;
        font-size: 40px;
    }
    .newsletter-form {
        position: relative;

        .form-control {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 70px;
            padding: 15px 25px;
            border-radius: 10px;
            outline: 0;
            color: $paragraph-color;
            font-size: 15px;

            &::placeholder {
                color: $paragraph-color;
                transition: $transition;
            }
            &:focus {
                box-shadow: unset;

                &::placeholder {
                    color: transparent;
                }
            }
        }
        .main-default-btn {
            background: $main-gradient-color;
            color: $white-color !important;
            padding: 20px;
            position: absolute;
            top: 4px;
            right: 4px;
        }
        #validator-newsletter {
            color: $white-color;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}

/*----- Subscribe Style Area CSS -----*/
.footer-style-area {
    background: $black-color;

    .copyright-style-area {
        margin-top: 70px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #212e37;

        .copyright-item {
            p {
                margin-bottom: 0;
                color: #e3e9ed;
                font-size: 15px;

                a {
                    display: inline-block;
                    color: $main-color;
                    font-weight: 600;

                    &:hover {
                        letter-spacing: 1px;
                    }
                }
            }
            .payment-list {
                margin: 0;
                padding: 0;
                text-align: end;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;

                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}
.single-footer-widget {
    margin-bottom: 25px;

    .footer-logo {
        a {
            img {
                margin-bottom: 30px;
            }
        }
        p {
            color: $white-color;
            margin-bottom: 20px;
            font-size: 15px;
        }
        .footer-social {
            padding: 0;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    i {
                        display: inline-block;
                        height: 35px;
                        width: 35px;
                        line-height: 35px;
                        text-align: center;
                        border-radius: 50px;
                        background: $main-color;
                        color: $white-color;
                        transition: $transition;
                        font-size: 15px;

                        &:hover {
                            background-color: $white-color;
                            color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
        color: $white-color;
    }
    .quick-link {
        padding: 0 0 0 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 15px;
            position: relative;
            padding-left: 22px;
            font-size: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                background: $main-gradient-color;
                border-radius: 50px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .address-info {
        padding: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 15px;
            font-size: 15px;
            color: $white-color;
            position: relative;
            padding-left: 22px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            a {
                color: $white-color;
                font-size: 15px;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*----- Offer Style Area CSS -----*/
.offer-style-card {
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        width: 100%;
        height: 90%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.4) 61.46%,
            rgba(255, 255, 255, 0.9) 100%
        );
        bottom: 0;
    }
    .offer-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0px 30px 35px 30px;

        h3 {
            font-size: 20px;
            color: $black-color;
            margin-bottom: 12px;
            font-weight: bold;
            line-height: 1.5;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 15px;
            transition: $transition;
        }
    }
    &:hover {
        transform: translateY(10px);

        .offer-content {
            a {
                color: $main-color;
            }
        }
    }
    &.with-black-color {
        &::before {
            background: linear-gradient(
                180deg,
                rgba(13, 24, 32, 0) 0%,
                #000000 100%,
                rgba(0, 0, 0, 0.7) 100%
            );
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            width: 100%;
            height: 90%;
            background: linear-gradient(
                180deg,
                rgba(0, 193, 148, 0) 0%,
                rgba(0, 173, 133, 0.682292) 61.98%,
                #00a47e 100%
            );
            bottom: 0;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
        }
        .offer-content {
            z-index: 1;

            h3 {
                color: $white-color;
                transition: $transition;
            }
            a {
                color: $white-color;
                transition: $transition;
            }
        }
        &:hover {
            &::after {
                opacity: 1;
                visibility: visible;
            }
            .offer-content {
                h3 {
                    color: $white-color;
                }
                a {
                    color: $white-color;
                }
            }
        }
    }
}
.offer-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #dddddd;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover,
                &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled + .owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.offer-style-area-with-full-width {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .container-fluid {
        left: calc((100% - 1320px) / 2);
        position: relative;
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 950px;
        background: #f5f7fb;
        border-radius: 0px 100px 0px 0px;
        z-index: -1;
    }
}
.offer-style-section-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 15px;
        color: $main-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
    }
}
.offer-style-item {
    margin-bottom: 30px;

    .offer-image {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            width: 100%;
            height: 90%;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.4) 61.46%,
                rgba(0, 0, 0, 0.8) 100%
            );
            bottom: 0;
        }
    }
    .offer-content {
        padding: 30px;
        border: 1px solid #ebe9ee;
        background-color: $white-color;
        margin-top: 10px;
        transition: $transition;

        h3 {
            font-size: 18px;
            color: $black-color;
            margin-bottom: 12px;
            font-weight: bold;
            line-height: 1.6;
            transition: $transition;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 14px;
            transition: $transition;
            transition: $transition;
        }
    }
    &:hover {
        .offer-content {
            border: 1px solid $main-color;
            background-color: $main-color;

            h3 {
                color: $white-color;
            }
            a {
                color: $white-color;
            }
        }
    }
}

/*----- Get Introduced Area CSS -----*/
.get-introduced-area {
    background-color: $main-color;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
    &::before {
        position: absolute;
        content: "";
        width: 250px;
        height: 100%;
        background-color: $white-color;
        right: 0;
        top: 0;
        z-index: -1;
    }
    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 90px;
        background-color: $white-color;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}
.get-introduced-content {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $white-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
        color: $white-color;
    }
    p {
        margin-bottom: 0;
        color: $white-color;
        font-size: 15px;
    }
    .introduced-inner-box {
        background-color: #00ad85;
        padding: 35px 20px 35px 85px;
        border-radius: 5px;
        position: relative;
        margin-top: 30px;

        .icon {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);

            i {
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                background-color: $main-color;
                color: $white-color;
                text-align: center;
                font-size: 25px;
                border-radius: 64% 36% 35% 65% / 65% 44% 56% 35%;
            }
        }
        span {
            color: $white-color;
            font-size: 15px;
        }
    }
    .introduced-counter-item {
        margin-top: 80px;

        .counter-card {
            position: relative;
            margin-top: 25px;

            h3 {
                font-weight: 700;
                font-size: 55px;
                color: $main-color;
                margin-bottom: 0;
                position: absolute;
                top: 0;
                left: 0;
            }
            p {
                margin-bottom: 0;
                font-size: 15px;
                color: $black-color;
                padding-left: 85px;
                padding-top: 10px;
                font-weight: 500;
            }
        }
    }
}

/*----- Video Overview Area CSS -----*/
.video-overview-image {
    position: relative;
    z-index: 1;
    text-align: center;

    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: $transition;
        background: $main-gradient-color;
        z-index: 1;
        margin: 50px;
        opacity: 0.77;
    }
    .video-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        i {
            width: 100px;
            height: 100px;
            line-height: 100px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            background: $white-color;
            color: $main-color;
            font-size: 70px;
            margin-right: 10px;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
        span {
            color: $black-color;
            font-weight: 500;
            font-size: 14px;
            position: relative;
            top: -5px;
        }
    }
}
.video-overview-content {
    padding-left: 45px;

    .overview-inner-box {
        margin-bottom: 35px;
        position: relative;
        padding-left: 100px;

        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 1.5px;

            i {
                display: inline-block;
                width: 80px;
                height: 75px;
                line-height: 75px;
                border-radius: 10px;
                background: #e5f9f4;
                color: $main-color;
                font-size: 45px;
                text-align: center;
                transition: $transition;
            }
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 10px;
            font-size: 15px;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 15px;
            transition: $transition;
        }
        &:hover {
            .icon {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Get Address Area CSS -----*/
.get-address-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    .inner-box {
        margin-top: 30px;
        position: relative;
        padding-left: 75px;

        &::before {
            width: 1px;
            height: 140%;
            background-color: $main-color;
            content: "";
            z-index: -1;
            position: absolute;
            left: 28px;
            top: 0;
        }
        &:last-child {
            &::before {
                display: none;
            }
        }
        .number {
            display: inline-block;
            height: 50px;
            width: 50px;
            line-height: 50px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            font-size: 25px;
            border-radius: 64% 36% 35% 65% / 65% 44% 56% 35%;
            font-weight: bold;
            font-style: italic;
            position: absolute;
            left: 0;
            top: 0;
        }
        h4 {
            font-size: 22px;
            margin-bottom: 12px;
            font-weight: bold;
        }
        p {
            color: $paragraph-color;
        }
    }
}
.get-address-form {
    background-color: $main-color;
    border-radius: 15px;
    padding: 50px 65px;
    margin-left: 35px;

    h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        color: $white-color;
    }
    .loan-check-box {
        margin: {
            top: 25px;
            bottom: 25px;
        }
        .form-check {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .form-check-label {
                color: $white-color;
            }
            .form-check-input {
                font-size: 15px;

                &:checked {
                    background-color: $black-color;
                    border-color: $black-color;
                }
                &:focus {
                    box-shadow: unset;
                }
            }
        }
    }
    form {
        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500;
                color: $white-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: #f8fafb;
                border: 1px solid #e3e9ed;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: $paragraph-color;

                &::placeholder {
                    color: $paragraph-color;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .nice-select {
                float: unset;
                height: 55px !important;
                line-height: 55px;
                color: $paragraph-color;
                background: #f8fafb;
                border: 1px solid #e3e9ed;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: 20px;
                    right: 35px;
                }
                .list {
                    background: $main-gradient-color;
                    border-radius: 10px;
                    margin-top: 0;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .option {
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        }
                        color: $white-color;
                        font-weight: 500;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
                &:after {
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -5.5px;
                }
            }
        }
        .loan-button {
            text-align: center;
            margin-top: 30px;

            .default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $black-color;
                color: $white-color;
                width: 100%;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
}

/*----- FAQ Style Area CSS -----*/
.faq-style-accordion {
    max-width: 870px;
    margin: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 35px;
        font-weight: bold;
    }
    .accordion-item {
        margin-bottom: 20px;
        transition: $transition;
        border-radius: 0;
        border: none;

        .accordion-button {
            color: $black-color;
            position: relative;
            text-align: start;
            box-shadow: unset;
            margin-bottom: 0;
            display: block;
            border: none;
            width: 100%;
            font-size: 18px;
            font-weight: 600;
            padding: 25px 65px 25px 25px;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;
            border-radius: 0;

            &::before {
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                content: "-";
                font-size: 15px;
                color: $transition;
                display: inline-block;
                height: 20px;
                width: 20px;
                line-height: 20px;
                text-align: center;
                background-color: $white-color;
                color: $main-color;
                border-radius: 50px;
            }
            &.collapsed {
                background-color: #e5f9f4;
                color: $black-color;

                &::before {
                    content: "+";
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
        .accordion-body {
            padding: 20px 0 25px 20px;
            transition: $transition;
            border: none;

            p {
                color: $paragraph-color;
                font-size: 15px;
                margin-bottom: 0;
            }
        }
        .accordion-collapse {
            border: none;
        }
        &:last-child {
            margin-bottom: 0;

            .accordion-body {
                padding-bottom: 0;
            }
        }
    }
}
.faq-style-area-with-full-width {
    overflow: hidden;

    .container-fluid {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
    .faq-style-accordion {
        max-width: 640px;
        margin: 0 auto 0 0;
    }
    &.with-black-color {
        background-color: $black-color;

        .faq-style-accordion {
            h3 {
                color: $white-color;
            }
            .accordion-item {
                background-color: transparent;

                .accordion-body {
                    padding-right: 15px;

                    p {
                        color: $white-color;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
.faq-style-area-with-full-width-wrap {
    background-color: $black-color;
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }
    }
    .faq-style-accordion {
        max-width: 640px;
        margin: 0 0 0 auto;
        padding: {
            top: 100px;
            bottom: 100px;
            right: 50px;
        }
        .sub-title {
            color: $main-color;
            display: inline-block;
            margin-bottom: 18px;
        }
        h3 {
            font-size: 40px;
            margin-bottom: 35px;
            font-weight: bold;
            color: $white-color;
        }
        .accordion-item {
            background-color: transparent;

            .accordion-body {
                padding-right: 15px;

                p {
                    color: $white-color;
                    font-size: 15px;
                }
            }
        }
    }
    &.bg-transparent {
        background-color: transparent;

        .faq-style-accordion {
            max-width: 640px;
            margin: 0 auto 0 0;
            padding: {
                top: 0;
                bottom: 0;
                right: 0;
                left: 50px;
            }
            .sub-title {
                color: $main-color;
            }
            h3 {
                color: $black-color;
            }
            .accordion-item {
                .accordion-body {
                    p {
                        color: $paragraph-color;
                    }
                }
            }
        }
        .faq-style-image-wrap {
            border-radius: 0px 0px 100px 0px;
        }
    }
}

/*----- Information Area CSS -----*/
.information-area {
    position: relative;
    z-index: 1;

    &.with-bg-black-color {
        background-color: #172632;

        .information-card {
            .icon {
                i {
                    background: rgba(0, 193, 149, 0.2);
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $main-color;
                    }
                }
            }
        }
    }
    &.with-bg-main-color {
        background-color: $main-color;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100px;
            background-color: $white-color;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .information-card {
            .icon {
                i {
                    background: #00a47e;
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $black-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $white-color;
                        color: $main-color;
                    }
                }
            }
        }
    }
}
.information-card {
    position: relative;
    margin-bottom: 30px;
    padding-left: 82px;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        i {
            display: inline-block;
            height: 65px;
            width: 65px;
            line-height: 65px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
            transition: $transition;
        }
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;

        span {
            display: block;
            color: $black-color;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    &:hover {
        .icon {
            i {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.information-shape-1 {
    position: absolute;
    left: 50px;
    bottom: 250px;
    z-index: -1;
}
.information-shape-2 {
    position: absolute;
    right: 50px;
    top: 100px;
    z-index: -1;
}

/*----- Beyond Style Area CSS -----*/
.beyond-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.beyond-style-list-tab {
    position: relative;

    #thumbs {
        margin-bottom: 50px;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            height: 1px;
            width: 100%;
            background-color: $main-color;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }
        .tabs-item {
            text-align: center;

            span {
                display: inline-block;
                height: 75px;
                width: 75px;
                line-height: 75px;
                text-align: center;
                border-radius: 50%;
                font-size: 32px;
                font-weight: bold;
                border: 1px solid $main-color;
                background-color: $white-color;
                color: $black-color;
            }
        }
        .current {
            .tabs-item {
                span {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: $white-color;
                }
            }
        }
    }
    #beyond-style-item {
        .beyond-style-content {
            padding-right: 20px;

            h3 {
                font-size: 40px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .bold {
                color: $black-color;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    .owl-theme {
        .owl-nav {
            [class*="owl-"] {
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                padding: 0;
                transition: $transition;
                font-size: 35px;
                display: inline-block;
                height: 75px;
                width: 75px;
                line-height: 0px;
                background-color: $white-color;
                color: $black-color;
                border: 1px solid $main-color;
                border-radius: 50px;
                z-index: 1;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &.disabled {
                    background-color: $main-color;
                    color: $black-color;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border: 1px solid $main-color;
                }
            }
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*----- Fun Fact Style Area CSS -----*/
.fun-fact-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.fun-fact-style-card {
    position: relative;
    background-color: #f5f7fb;
    padding: 35px 35px 35px 123px;
    border-radius: 5px;
    margin: {
        top: 12px;
        bottom: 12px;
    }
    transition: $transition;
    h3 {
        font-weight: 700;
        font-size: 55px;
        color: $main-color;
        margin-bottom: 0;
        position: absolute;
        top: 28px;
        left: 35px;
    }
    p {
        font-size: 18px;
        color: $black-color;
        font-weight: 500;
        margin-bottom: 0;
    }
    &.bg-E5F9F4 {
        background-color: #e5f9f4;
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.fun-fact-style-content {
    padding-left: 40px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
    }
    .fun-fact-btn {
        margin-top: 20px;

        .main-default-btn {
            color: $white-color;
        }
    }
}
.fun-fact-style-shape {
    position: absolute;
    right: -30px;
    bottom: -350px;
    z-index: -1;
}
.fun-fact-style-area-with-main-color {
    background-color: $main-color;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.fun-fact-style-item {
    position: relative;
    background-color: #f5f7fb;
    padding: 35px 35px 35px 123px;
    border-radius: 5px;
    margin-bottom: 30px;
    transition: $transition;

    h3 {
        font-weight: 700;
        font-size: 55px;
        color: $main-color;
        margin-bottom: 0;
        position: absolute;
        top: 28px;
        left: 35px;
    }
    p {
        font-size: 18px;
        color: $black-color;
        font-weight: 500;
        margin-bottom: 0;
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.fun-fact-style-shape-2 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 50%;
}

/*----- Find Loan Area CSS -----*/
.find-own-loan-area {
    background: linear-gradient(
        180deg,
        #f5f7fb 0%,
        rgba(245, 247, 251, 0) 100%
    );
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.find-own-loan-list-tab {
    .nav {
        padding: 0;
        margin-bottom: 45px;
        border: none;
        border-bottom: 1px solid #dddddd;
        text-align: center;
        display: block;

        .nav-item {
            margin-right: 30px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
            .nav-link {
                transition: $transition;
                color: $black-color;
                padding: 0;
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 15px;
                position: relative;
                padding-bottom: 20px;

                &::before {
                    position: absolute;
                    content: "";
                    height: 1px;
                    width: 0%;
                    background-color: $main-color;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover,
                &.active {
                    color: $main-color;

                    &::before {
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.find-own-loan-card {
    margin-bottom: 30px;

    .loan-content {
        position: relative;
        margin-top: -30px;

        .tag {
            display: inline-block;
            padding: 8px 22px;
            background-color: $main-color;
            color: $white-color;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 22px;
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.find-own-loan-slider {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            transition: $transition;

            [class*="owl-"] {
                color: $black-color;
                margin: 0;
                position: absolute;
                left: -70px;
                top: 30%;
                transform: translateY(-30%);
                padding: 0;
                transition: $transition;
                background-color: $main-color;
                color: $white-color;
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                border-radius: 50px;
                font-size: 25px;

                &.owl-next {
                    left: auto;
                    right: -70px;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}
.find-own-loan-box {
    margin-bottom: 30px;
    transition: $transition;

    .loan-image {
        position: relative;

        .tag {
            display: inline-block;
            padding: 8px 22px;
            background-color: $main-color;
            color: $white-color;
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            left: 20px;
            bottom: 20px;
            border-radius: 5px;
        }
    }
    .loan-content {
        margin-top: 25px;

        h3 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
            font-size: 15.8px;
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.find-own-loan-item {
    background-color: #f5f7fb;
    padding: 30px;
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    .icon {
        margin-bottom: 20px;

        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            font-size: 45px;
            border-radius: 50px;
            transition: $transition;
        }
    }
    .loan-content {
        h3 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 15px;
            transition: $transition;
        }
        p {
            color: #767676;
            transition: $transition;
            font-size: 15px;
        }
    }
    .tag {
        position: absolute;
        right: 15px;
        top: 10px;
        color: $main-color;
        font-size: 15px;
        font-weight: 100;
    }
    &:hover {
        background-color: $black-color;

        .icon {
            i {
                background-color: $white-color;
                color: $main-color;
            }
        }
        .loan-content {
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
        }
    }
}

/*-----  Expect Style Area CSS -----*/
.expect-style-area {
    background-color: $black-color;

    .main-section-title {
        max-width: 630px;
        margin: 0 auto 45px;

        h2 {
            color: $white-color;
        }
    }
}
.expect-style-card {
    background-color: #172632;
    padding: 35px 50px;
    margin-bottom: 30px;
    border: 1px solid #172632;
    transition: $transition;

    h3 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 18px;
        color: $white-color;
    }
    p {
        font-size: 15px;
        color: #dddddd;
        margin-bottom: 18px;
    }
    a {
        font-size: 15px;
        font-weight: 500;
        color: $white-color;
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);

        a {
            color: $main-color;
        }
    }
}

/*-----  Get Quote Style Area CSS -----*/
.get-quote-style-area {
    background-color: $main-color;
    padding-top: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.get-quote-style-content {
    position: relative;
    top: -15px;

    .sub-title {
        color: $white-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $white-color;
    }
    .quote-btn {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        }
        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 25px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                background-color: $black-color;
                color: $white-color;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
            .main-optional-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $white-color;
                color: $black-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}
.get-quote-style-shape-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 50%;
}
.get-quote-style-shape-2 {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: -1;
}

/*----- Process Style Area CSS -----*/
.process-style-area {
    background-image: url(assets/img/more-home/process/process-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.process-style-accordion {
    .accordion-item {
        border: none;
        transition: $transition;
        border-radius: 0;
        position: relative;
        z-index: 1;
        background-color: transparent;

        &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            z-index: -1;
            left: 25px;
            top: 0;
            background-color: $main-color;
        }
        &:last-child {
            .accordion-button {
                padding-bottom: 0;
            }
            .accordion-body {
                padding: {
                    top: 10px;
                    bottom: 0;
                }
            }
        }
        .accordion-button {
            color: $black-color;
            box-shadow: unset;
            margin-bottom: 0;
            display: block;
            border: none;
            width: 100%;
            font-size: 18.8px;
            font-weight: 600;
            background-color: transparent;
            padding: 0 0 15px 0;

            span {
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                background-color: $main-color;
                color: $white-color;
                border-radius: 50px;
                margin-right: 10px;
                font-size: 16px;
            }
        }
        .accordion-body {
            padding: 0px 0 20px 60px;

            .bold {
                color: $black-color;
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 10px;
            }
            p {
                color: $paragraph-color;
                font-size: 15px;
                margin-bottom: 0;
                font-size: 15px;
            }
        }
    }
}
.process-style-wrap-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 0;
        bottom: 10px;
        right: 10px;
    }
    border-radius: 0px 0px 100px 0px;

    img {
        border-radius: 0px 0px 100px 0px;
    }
    &::before {
        position: absolute;
        content: "";
        height: 495px;
        width: 550px;
        background-color: transparent;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 0px 0px 100px 0px;
        border: 1px solid $main-color;
    }
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
    img {
        top: 50%;
        left: 20px;
        width: 15px;
        position: absolute;
        transform: translateY(-50%);
    }
    right: 30px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: $white-color !important;
    background-color: #82b440;
    padding: 10px 20px 10px 42px;
    font-size: 15px;
    font-weight: 600;
    animation: {
        name: tada;
        duration: 5s;
        fill-mode: both;
        iteration-count: infinite;
    }
    &:hover {
        background-color: $main-color;
        color: $white-color !important;
    }
}
@keyframes tada {
    0% {
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

.four-expect-area {
    background-color: $white-color;

    .three-section-title {
        h2 {
            margin-left: auto;
            margin-right: auto;
            color: $black-color;
        }
    }
    .expect-item {
        background-color: $black-color;
        position: relative;
        border-radius: 4px;
        padding: 45px 35px 45px 115px;
        transition: $transition;
        margin-bottom: 30px;
        min-width: 100%;

        &:hover,
        &.active {
            background-color: $black-color;

            h3 {
                color: $white-color;
            }
            p {
                color: #bedaef;
            }
            a {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                i {
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .expect-icon {
            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                margin-bottom: 30px;
                position: absolute;
                top: 45px;
                left: 35px;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 10px;
            transition: $transition;
        }
        p {
            margin-bottom: 10px;
            font-size: 15px;
            color: #bedaef;
            transition: $transition;
        }
        a {
            display: inline-block;
            font-size: $all-size;
            color: #bedaef;

            i {
                display: inline-block;
                position: relative;
                top: 4px;
                font-size: 18px;
                transition: $transition;
            }
        }
    }
}
.process-item {
    box-shadow: 1px 1px 65px 1px #dddddd6e;
    border-radius: 4px;
    position: relative;
    padding: 55px 20px 30px 40px;
    transition: $transition;
    margin-left: 100px;
    margin-bottom: 10px;
    max-width: 320px;
    background-color: $white-color;

    &:hover {
        background-color: #12ca59;

        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
    .process-span {
        display: inline-block;
        background-color: $white-color;
        position: absolute;
        top: 35px;
        left: 250px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        border-radius: 50%;
        transition: $transition;

        span {
            display: inline-block;
            width: 70px;
            height: 70px;
            line-height: 70px;
            border-radius: 50%;
            box-shadow: 0px 0px 25px 0px #dddddd6e;
            text-align: center;
            background: $gradient-green;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 600;
            font-size: 26px;
            transition: $transition;
        }
    }
    h3 {
        font-weight: 500;
        font-size: 20px;
        color: #1a1b1e;
        margin-bottom: 6px;
        transition: $transition;
    }
    p {
        margin-bottom: 0;
        color: #848484;
        font-size: $all-size;
        max-width: 200px;
        transition: $transition;
    }
}
.process-two {
    margin-left: auto;

    &:nth-child(2) {
        margin-right: -25px;
        margin-left: auto;
    }
    &:before {
        transform: rotate(180deg);
        right: unset;
        left: -59px;
        top: 35%;
    }
}
.process-img {
    text-align: center;
    margin-bottom: 30px;
    position: relative;

    img {
        &:nth-child(1) {
            margin-left: 40px;
            position: relative;
            z-index: 1;
        }
        &:nth-child(2) {
            position: absolute;
            left: -30px;
            bottom: 30px;
            max-width: 105px;
            z-index: -1;
            margin-left: 40px;
        }
        &:nth-child(3) {
            position: absolute;
            left: -20px;
            bottom: 0;
            max-width: 100px;
            z-index: -1;
            margin-left: 40px;
        }
        &:nth-child(4) {
            position: absolute;
            left: -75px;
            bottom: 8px;
            z-index: -1;
        }
        &:nth-child(5) {
            position: absolute;
            left: -85px;
            bottom: 0px;
            opacity: 0.2;
        }
        &:nth-child(6) {
            position: absolute;
            right: -110px;
            bottom: 8px;
            z-index: -1;
        }
        &:nth-child(7) {
            position: absolute;
            right: -103px;
            bottom: 0px;
            opacity: 0.2;
        }
    }
}
.two-help-area {
    .help-img-two {
        background-image: url("assets/img/home-two/bit-tornado.webp");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        img {
            display: none;
        }
    }
    .help-item {
        max-width: 680px;
        margin-left: auto;
        padding-right: 55px;
        padding-top: 100px;
        padding-bottom: 100px;

        ul {
            li {
                flex: 0 0 100%;
                max-width: 100%;

                .help-inner {
                    p {
                        max-width: 285px;
                    }
                }
            }
        }
    }
}
